import { Capability } from '../../models';
import { CCApiService } from './api-service';
import { UIConfig } from './config-service';

export class CapabilitiesApiService {
  static ApiService: CCApiService;
  static capabilitiesApi: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.capabilitiesApi = `${this.apiBasePath}/capabilities`;
  }

  static async getCapabilities(): Promise<Capability> {
    return this.ApiService.performFetch(this.capabilitiesApi).then(capabilities => capabilities);
  }
}
